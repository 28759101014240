<template>
	<div v-if="loaded" class="dashboard">
		<BRow>
			<BCol>
				<WelcomeMember :member-info="memberInfo" class="member-card" automation-id="dashboard" />
			</BCol>
		</BRow>
		<BRow>
			<BCol>
				<DashboardActionRow automation-id="dashboard" />
			</BCol>
		</BRow>
		<BRow v-if="!maaxClaims" class="my-claims-section">
			<BCol v-if="showClaimsTracker">
				<div class="recent-claims-header">
					<h2 class="recent-claims-title h3 d-inline">{{ t('header.recentClaims') }}</h2>
					<InfoModal
						class="my-claims-info-button d-inline"
						:button-icon="['fal', 'info-circle']"
						automation-id="my-claims-info-button"
						:modal-title="t('header.recentClaims')"
					>
						{{ t('modal.textHistory') }}<br /><br />{{ t('modal.textClaims') }}<br /><br />{{
							t('modal.textPrescription')
						}}
					</InfoModal>
				</div>
				<div class="tabs-container">
					<BaseTabs
						:tab-index="tabIndex"
						:tabs="tabs"
						:tab-with-icon="1"
						:icon="['fas', 'exclamation-circle']"
						automation-id="dashboard"
						:show-icon="showTabIcon"
						@click="onTabClick"
					>
						<template #title> {{ t('tab.claims') }}</template>
						<template #0>
							<DashboardPaymentHistoryTab automation-id="dashboard" />
						</template>
						<template #1>
							<DashboardClaimsTrackerTab
								automation-id="dashboard"
								@isEmptyClaims="(value) => showAlertIcon(value)"
							/>
						</template>
					</BaseTabs>
				</div>
			</BCol>
			<BCol v-else>
				<DashboardPaymentHistoryRow automation-id="dashboard" />
			</BCol>
		</BRow>
		<BRow v-else>
			<BCol>
				<BaseCard body-class="p-4">
					<div class="d-flex flex-wrap">
						<h2 class="h2-recent-claims mb-0 flex-grow-1 pr-2">{{ t('header.recentClaims') }}</h2>
						<div>
							<BaseButton
								class="p-0"
								:pill="false"
								variant="link"
								automation-id="btn-maax-full-claim-history"
								:label="t('maaxRecentClaims.fullHistory')"
								type="button"
								to="history"
							/>
						</div>
					</div>
					<p class="mb-2">{{ t('maaxRecentClaims.description') }}</p>
					<HistoryTable
						v-if="history.claimServices.length > 0"
						:history-fields="historyFields"
						:history-headers="historyHeaders()"
						:history="history.claimServices"
						:bottom-margin="false"
					/>
					<p v-else class="m-0 text-bold">{{ t('maaxRecentClaims.none') }}</p>
				</BaseCard>
			</BCol>
		</BRow>
		<BRow v-if="connectedCare">
			<BCol>
				<ConnectedCareCard v-if="connectedCare" automation-id="dashboard" />
			</BCol>
		</BRow>
		<!-- row is inside of component below -->
		<DashboardBottomCards automation-id="dashboard" />
	</div>
</template>
<script setup>
import {
	defineProps,
	watch,
	getCurrentInstance,
	computed,
	ref,
	onMounted,
	onBeforeUnmount
} from 'vue';
import { BRow, BCol } from 'bootstrap-vue';
import store from '@/store';
import { parseISO } from 'date-fns';
// composables
import { useI18n } from '@/composables/useI18n';
// components
import WelcomeMember from '@/components/dashboard/WelcomeMember';
import DashboardActionRow from '@/components/dashboard/DashboardActionRow';
import ConnectedCareCard from '@/components/dashboard/ConnectedCareCard';
import DashboardBottomCards from '@/components/dashboard/DashboardBottomCards';
import DashboardPaymentHistoryTab from '@/components/dashboard/DashboardPaymentHistoryTab';
import DashboardPaymentHistoryRow from '@/components/dashboard/DashboardPaymentHistoryRow';
import DashboardClaimsTrackerTab from '@/components/dashboard/DashboardClaimsTrackerTab.vue';
import InfoModal from '@/components/common/InfoModal';
import BaseTabs from '@/components/common/base/BaseTabs.vue';
import BaseCard from '@/components/common/card/BaseCard.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
import HistoryTable from '@/components/history/HistoryTable.vue';
// models
import ContactInfo from '@/models/ContactInfo';
import MemberCardInfo from '@/models/MemberCardInfo';
import History from '@/models/claims/History';
import Dependent from '@/models/Dependent.js';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;

defineProps({
	userEmail: {
		type: String,
		default: ''
	}
});

watch(
	() => root.$i18n.locale,
	async () => {
		if (maaxClaims) {
			const email = sessionStorage.getItem('email');
			const token = sessionStorage.getItem('apiToken');
			const hasHistory = await History.hasHistory(
				email,
				participants.value.map((p) => p.participantId),
				root.$i18n.locale,
				'recent'
			);

			if (!hasHistory) {
				loaded.value = false;
				store.dispatch('updateLoading', true);
				store.dispatch('updateBackgroundImageStatus', false);
			}

			history.value = combineHistoryData(
				await History.getHistory(
					email,
					token,
					root.$i18n.locale,
					'recent',
					participants.value.map((p) => p.participantId)
				)
			);
		}
		updateTabs();

		loaded.value = true;
		store.dispatch('updateLoading', false);
		store.dispatch('updateBackgroundImageStatus', true);
	}
);

// static
const securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
const storedClaimsTabVisited = sessionStorage.getItem('claimsTabVisited');
const { brand, national, storeFront, maaxClaims } = securityPolicy;
const historyFields = [
	'dateProcessed',
	'serviceInfo',
	'member',
	'serviceDate',
	'paidAmount',
	'paymentMethod',
	'hasPaymentDetails',
	'claimId'
];

// refs
const tabs = ref([]);
const showTabIcon = ref(false);
const claimsTabVisited = ref(false);
const showClaimsTracker = ref(false);
const showClaimsTab = ref(true);
const tabIndex = ref(0);
const memberInfo = ref(null);
const participants = ref(null);
const history = ref(null);
const loaded = ref(false);

// computed
const connectedCare = computed(() => {
	return storeFront && brand !== 'SBC';
});

// methods
function historyHeaders() {
	return [
		{ key: 'dateProcessed', label: t('maaxRecentClaims.table.dateProcessed') },
		{ key: 'serviceInfo', label: t('maaxRecentClaims.table.benefitDesc') },
		{ key: 'member', label: t('maaxRecentClaims.table.member') },
		{ key: 'serviceDate', label: t('maaxRecentClaims.table.serviceDate') },
		{ key: 'paidAmount', label: t('maaxRecentClaims.table.blueCrossPaid') },
		{ key: 'paymentMethod', label: t('maaxRecentClaims.table.payment') },
		{ key: 'hasPaymentDetails', label: t('maaxRecentClaims.table.statement') }
	];
}
// This method is called when the local changes to update the tabs array with the correct locale.
function updateTabs() {
	tabs.value = [];
	tabs.value.push(t('tab.payments'));
	showClaimsTab.value && showClaimsTracker.value ? tabs.value.push(t('tab.claims')) : null;
}
// This method hides the icon on the claims tab when visited.
function onTabClick(index) {
	if (index === 1) {
		showTabIcon.value = false;
		claimsTabVisited.value = true;
		sessionStorage.setItem('claimsTabVisited', claimsTabVisited.value);
	}
}
// This method shows the tab icon if recent claims are available.
function showAlertIcon(isEmptyClaims) {
	showTabIcon.value = !isEmptyClaims && !claimsTabVisited.value ? true : false;
}
// combines history data into one Object with claimServiceDetails array which is what the table uses
function combineHistoryData(data) {
	const newData = [...data].reduce(
		(total, current) => {
			const serviceDetails = current.claimServices.map((s) => {
				s.member = current.participantInfo.firstName;
				return s;
			});
			total.claimServices = [...total.claimServices, ...serviceDetails];
			return total;
		},
		{ claimServices: [] }
	);
	newData.claimServices = newData.claimServices
		.sort((a, b) => parseISO(b.dateProcessed) - parseISO(a.dateProcessed))
		.slice(0, 5);
	return newData;
}

// lifecycle methods
onMounted(async () => {
	const { claimTracker } = JSON.parse(sessionStorage.getItem('securityPolicy'));
	const email = sessionStorage.getItem('email');
	const token = sessionStorage.getItem('apiToken');
	let memberAddress;

	store.dispatch('updateLoading', true);

	const results = await Promise.all([
		Dependent.getDependents(email, token, root.$i18n.locale, true),
		ContactInfo.getUserInfo(email, token, root.$i18n.locale),
		MemberCardInfo.getMemberDetail(email, token, root.$i18n.locale)
	]);

	participants.value = results[0].data;
	memberAddress = results[1];
	memberInfo.value = results[2];

	if (maaxClaims) {
		history.value = combineHistoryData(
			await History.getHistory(
				email,
				token,
				root.$i18n.locale,
				'recent',
				participants.value.map((p) => p.participantId)
			)
		);
	}

	if (
		(national && brand === 'SBC' && memberAddress?.province !== 'SK') ||
		(national &&
			brand === 'MBC' &&
			!['PE', 'NB', 'NS', 'NF', 'NL'].includes(memberAddress?.province))
	) {
		store.dispatch('dashboardInfoBanner', { show: true });
	} else {
		store.dispatch('dashboardInfoBanner', { show: false });
	}

	store.dispatch('updateBackgroundImageStatus', true);

	tabs.value.push(t('tab.payments'));
	showClaimsTracker.value = claimTracker;

	updateTabs();

	claimsTabVisited.value = storedClaimsTabVisited ? storedClaimsTabVisited : false;
	if (root.$route?.params?.isClaims) {
		tabIndex.value = 1;
	}

	loaded.value = true;
	store.dispatch('updateLoading', false);
});

onBeforeUnmount(() => {
	store.dispatch('updateBackgroundImageStatus', false);
});
</script>
<style lang="scss" scoped>
.h2-recent-claims {
	font-size: 24px;
	line-height: 1em;
}
.recent-claims-title {
	@media all and (max-width: 500px) {
		white-space: pre-line;
	}
}
.recent-claims-header {
	@media all and (max-width: 500px) {
		text-align: center;
	}
}
.member-card {
	margin-top: 40px;
}
.tabs-container {
	margin-top: 15px;
}
.my-claims-section {
	margin-top: 20px;
}
.tabs-container ::v-deep .nav-link {
	padding: 15px 30px;
}
.tabs-container ::v-deep .nav-link,
.tabs-container ::v-deep .nav-link:active {
	margin-left: 0;
	margin-right: 10px;
}
::v-deep .nav-tabs .svg-inline--fa {
	color: $warning-red;
}
.tabs-container ::v-deep .nav-link:hover:not(.active) {
	& .svg-inline--fa {
		color: white;
	}
}
</style>
<i18n lang="json">
{
	"en": {
		"tab": {
			"payments": "Payments",
			"claims": "Claims"
		},
		"header": {
			"recentClaims": "Recent Claims"
		},
		"modal": {
			"textHistory": "The Payments tab shows reimbursements processed as of the last business day. This may not reflect all the claims we're currently working on.",
			"textClaims": "The Claims tab includes the status of your recently submitted claims, and your claims history.",
			"textPrescription": "Prescription drug claims filed directly through your pharmacy can be found on the Claims History screen."
		},
		"maaxRecentClaims": {
			"fullHistory": "View full claims history >",
			"none": "We don't have any recently submitted claims on file.",
			"description": "Claims we have recently processed and paid, as of the last business day. This may not reflect all the claims we're currently working on.",
			"table": {
				"dateProcessed": "Date Processed",
				"benefitDesc": "Description",
				"serviceDate": "Service Date",
				"blueCrossPaid": "Blue Cross Paid",
				"statement": "Statement",
				"member": "Member",
				"payment": "Payment"
			}
		}
	},
	"fr": {
		"tab": {
			"payments": "Paiements",
			"claims": "Règlements"
		},
		"header": {
			"recentClaims": "Demandes de \nrèglement récentes"
		},
		"modal": {
			"textHistory": "L'onglet Paiements indique les remboursements traités en date du dernier jour ouvrable. Les demandes de règlement en processus de traitement pourraient ne pas y figurer.",
			"textClaims": "L'onglet Règlements indique l'état des demandes de règlement que vous avez récemment soumises, ainsi que votre historique de demandes de règlement.",
			"textPrescription": "Les demandes de règlement pour médicaments sur ordonnance soumises directement par votre pharmacie se trouvent à l'écran Historique des demandes de règlement."
		},
		"maaxRecentClaims": {
			"fullHistory": "Voir l'historique complet des demandes de règlement",
			"none": "Nos dossiers ne contiennent aucune demande de règlement récente.",
			"description": "Les demandes de règlement que nous avons traitées et payées récemment, en date du dernier jour ouvrable. Les demandes de règlement en processus de traitement pourraient ne pas y figurer.",
			"table": {
				"dateProcessed": "Date de traitement",
				"benefitDesc": "Description",
				"serviceDate": "Date du service",
				"blueCrossPaid": "Croix Bleue a payé",
				"statement": " Relevé",
				"member": "Adhérent(e)",
				"payment": "Type de paiement"
			}
		}
	}
}
</i18n>
