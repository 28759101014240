<template>
	<BaseCard automation-id="base-card" class="call-to-action-card mb-0">
		<div class="h-100 d-flex flex-column justify-content-between">
			<div>
				<FontAwesomeIcon
					aria-hidden="true"
					:icon="icon"
					:data-test-automation-id="getAutomationId('base-drop-down-icon-1')"
				/>
				<p class="title">{{ title }}</p>
				<p class="description">{{ description }}</p>
			</div>
			<div>
				<BaseButton
					:label="buttonText"
					:aria-label="buttonText"
					variant="secondary"
					:automation-id="getAutomationId('authentication-button')"
					:data-tracking-id="getAutomationId('authentication')"
					@click="hasClicked($event)"
				/>
			</div>
		</div>
	</BaseCard>
</template>
<script>
// This component renders a base card that has an icon displayed at the top and a title with some text and a button.
// It is used on the main dashboard page and emits a click event.
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseCard from '@/components/common/card/BaseCard';
import BaseButton from '@/components/common/base/BaseButton';

// @vue/component
@Component({
	name: 'CallToActionCard',
	components: {
		BaseCard,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		// Title to be displayed on the card.
		title: {
			type: String,
			default: null,
			required: true
		},
		//Description that is displayed under the title
		description: {
			type: String,
			default: null,
			required: true
		},
		// Text that will appear on the button
		buttonText: {
			type: String,
			default: null,
			required: true
		},
		// Icon to be displayed at the top of the card.
		icon: {
			type: Array,
			default: () => [],
			required: true
		}
	}
})
export default class CallToActionCard extends Vue {
	/**
	 * click event that is emited when user clicks button.
	 */
	hasClicked(event) {
		this.$emit('click', event);
	}
}
</script>

<style lang="scss" scoped>
.call-to-action-card {
	text-align: center;
	border: 1px solid $gray-light;
}
.call-to-action-card ::v-deep svg {
	font-size: 24px;
}
.call-to-action-card ::v-deep svg > path {
	color: $icon-grey;
}
.title {
	font-family: $font-family-headline;
	font-weight: 400;
	font-size: 24px;
	color: $black;
	padding-top: 20px;
	margin-bottom: 10px;
}
.description {
	font-family: $font-family-regular;
	font-weight: 300;
	font-size: 17px;
}
</style>
