<template>
	<div>
		<BaseButton
			class="status-button"
			:pill="false"
			size="sm"
			:variant="buttonVariant"
			:automation-id="getAutomationId('claimStatusButton')"
			data-tracking-id="claimStatusButton"
			:label="buttonLabel"
			:icon="iconVariant"
			icon-position="left"
			@click="showModal"
		/>

		<StatusInfoModal
			:automation-id="getAutomationId('claimsStatusInfoModal')"
			:modal-show="showInfoModal"
			@change="closeModal"
		/>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseModal from '@/components/common/base/BaseModal.vue';
import StatusInfoModal from '@/components/claims-tracker/StatusInfoModal';
import * as ClaimStatusCodes from '@/constants/ClaimStatusCodes';

/**
 * This component renders a status button in claims tracker table.
 */

@Component({
	name: 'StatusButton',
	components: {
		BaseButton,
		BaseModal,
		StatusInfoModal
	},
	mixins: [IdMixin],
	props: {
		label: {
			type: String,
			default: ''
		},
		index: {
			type: Number,
			default: null
		},
		array: {
			type: Array,
			default: null
		}
	}
})
export default class StatusButton extends Vue {
	showInfoModal = false;
	buttonVariant = '';
	buttonLabel = '';
	iconVariant = [];
	disabled = false;

	get locale() {
		return this.$store.state.i18n.locale;
	}

	beforeMount() {
		this.getButtonParams();
	}

	getButtonParams() {
		let status = this.array[this.index]?.statusCode.toUpperCase();
		if (status === ClaimStatusCodes.RECEIVED) {
			this.buttonVariant = 'outline-dark';
			this.buttonLabel = this.$t('submitted');
			this.iconVariant.push('fas', 'arrow-circle-right');
		} else if (status === ClaimStatusCodes.PAID) {
			this.buttonVariant = 'outline-success';
			this.buttonLabel = this.$t('processed');
			this.iconVariant.push('fal', 'check');
		} else if (status === ClaimStatusCodes.ADJUDICATED) {
			this.buttonVariant = 'outline-primary';
			this.buttonLabel = this.$t('inProgress');
			this.iconVariant.push('fas', 'spinner');
		} else if (status === ClaimStatusCodes.REJECTED) {
			this.buttonVariant = 'outline-danger';
			this.buttonLabel = this.$t('declined');
			this.iconVariant.push('fas', 'exclamation-triangle');
		} else if (status === ClaimStatusCodes.PENDED) {
			this.buttonVariant = 'outline-warning';
			this.buttonLabel = this.$t('pending');
			this.iconVariant.push('fas', 'clock');
		}
	}
	showModal() {
		this.showInfoModal = true;
	}

	closeModal() {
		this.showInfoModal = false;
	}
}
</script>

<style lang="scss" scoped>
.status-button {
	background-color: white;
	font-size: 12px;
	height: 25px;
	padding: 3px 6px 0 6px;
	&:disabled {
		opacity: 1;
		cursor: default;
	}
	&:hover,
	&:focus,
	&:active,
	&:active:focus {
		background-color: white;
		box-shadow: none;
	}
	&.btn-outline-primary {
		&:hover,
		&:focus,
		&:active,
		&:active:focus {
			color: $blue;
		}
	}
	&.btn-outline-dark {
		color: $black;
		border-color: $black;
	}
	&.btn-outline-success {
		&:hover,
		&:focus,
		&:active,
		&:active:focus {
			color: $green;
		}
	}
	&.btn-outline-danger {
		color: $light-red;
		border-color: $light-red;
		&:disabled,
		&:hover,
		&:focus,
		&:active,
		&:active:focus {
			color: $light-red;
			border-color: $light-red;
		}
	}
	&.btn-outline-warning {
		color: $orange-dark;
		border-color: $orange-dark;
		&:hover,
		&:focus,
		&:active,
		&:active:focus {
			color: $orange-dark;
		}
	}
	@media (max-width: 1000px) {
		white-space: nowrap;
	}
}
</style>

<i18n>
{
	"en": {
		"submitted": "Submitted",
		"processed": "Processed",
		"inProgress": "In Progress",
		"declined": "Declined",
		"pending": "Pending"
		},
	"fr": {
		"submitted": "Soumise",
		"processed": "Traitée",
		"inProgress": "En traitement",
		"declined": "Refusée",
		"pending": "En attente"
		}
}
</i18n>
