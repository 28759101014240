<template>
	<div
		class="payment-history-card"
		body-class="py-3 py-md-4"
		:automation-id="getAutomationId('payment-history')"
	>
		<BRow v-if="hasloaded">
			<BCol cols="12" md="6" order="1"
				><p class="h4">{{ $t('title') }}</p></BCol
			>
			<BCol cols="12" md="6" order="5" order-md="2">
				<BaseButton
					class="px-0 btn-history"
					:label="$t('button')"
					:icon="['fal', 'chevron-right']"
					variant="link"
					:automation-id="getAutomationId('payment-history')"
					@click="toHistory"
				/>
			</BCol>
			<BCol cols="auto" order-md="2" order="3">
				<p class="">{{ $t('content') }}</p>
			</BCol>
		</BRow>
		<BRow v-if="!isEmptyResults && hasloaded">
			<BCol class="px-0 px-md-3">
				<StatementSearchResults
					class="payment-table"
					:requires-label="false"
					:statement-search-results="paymentHistory.paymentLines"
					:per-page="5"
					:requires-pagination="false"
					:show-h-s-p="paymentHistory.showHSP"
					:automation-id="getAutomationId('payment-history')"
					@details="goToDetailsPage"
				/>
			</BCol>
		</BRow>
		<BRow v-if="isEmptyResults && hasloaded">
			<BCol>
				<p class="no-history mb-3">{{ $t('noHistory') }}</p>
			</BCol>
		</BRow>
		<BRow v-if="hasloaded">
			<BCol>
				<BaseButton
					class="p-0 pt-3 pt-lg-0"
					variant="link"
					automation-id="drug-claims-modal"
					:label="$t('drugClaims')"
					:icon="['fal', 'question-circle']"
					@click="showModal = true"
				/>
			</BCol>
		</BRow>
		<BRow v-if="showLoading">
			<BCol cols="12" class="text-center">
				<FontAwesomeIcon
					spin
					class="loading-icon text-center"
					:icon="['fas', 'spinner']"
				></FontAwesomeIcon>
			</BCol>
		</BRow>
		<BaseModal
			:modal-show="showModal"
			:modal-title="$t('drugClaims')"
			size="md"
			automation-id="drug-claims-modal"
			@change="showModal = false"
		>
			<template #contentBody>
				<i18n path="modal.text" tag="span">
					<template #claimsHistory>
						<router-link :to="{ name: claimHistoryLink }">
							<strong>{{ $t('modal.link') }}</strong>
						</router-link>
					</template>
				</i18n>
			</template>
		</BaseModal>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
// mixins
import IdMixin from '@/mixins/id';
// components
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import BaseModal from '@/components/common/base/BaseModal';
import StatementSearchResults from '@/components/payment-history/StatementSearchResults';
import loadingModal from '@/components/common/LoadingModal';
import PaymentDetails from '@/models/PaymentDetailsScepter';
import { PAYMENT_HISTORY_DETAILS, PAYMENT_HISTORY, CLAIM_HISTORY } from '@/constants/Routes.js';
import PaymentHistory from '@/models/PaymentHistory';
//utils
import { format } from 'date-fns';

@Component({
	name: 'DashboardPaymentHistoryTab',
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			required: true
		}
	},
	watch: {
		locale() {
			if (!this.isEmptyResults) {
				this.loadPaymentHistory(true);
			}
		}
	},
	components: {
		BRow,
		BCol,
		BaseCard,
		StatementSearchResults,
		BaseButton,
		loadingModal,
		BaseModal
	}
})
export default class DashboardPaymentHistoryTab extends Vue {
	showLoading = false;
	showModal = false;
	isEmptyResults = true;
	hasloaded = false;
	paymentHistory = [];

	get locale() {
		return this.$store.state.i18n.locale;
	}

	get claimHistoryLink() {
		return CLAIM_HISTORY;
	}

	getToday() {
		return format(new Date(), 'yyyy-MM-dd');
	}

	toHistory() {
		this.$router.push({ name: PAYMENT_HISTORY });
	}

	mounted() {
		this.loadPaymentHistory(false);
	}

	async loadPaymentHistory(localeChanged) {
		this.showLoading = true;
		this.hasloaded = false;
		this.searchCriteria = {
			fromDate: format(
				new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
				'yyyy-MM-dd'
			),
			toDate: this.getToday(),
			locale: this.$store.state.i18n.locale
		};
		if (!localeChanged) {
			this.paymentHistory = (await PaymentHistory.getSavedSearchResultsWithParams(
				sessionStorage.getItem('email'),
				this.searchCriteria
			)) || { paymentLines: [] };
		}
		this.isEmptyResults = !this.paymentHistory?.paymentLines?.length || false;
		if (this.isEmptyResults || localeChanged) {
			this.showLoading = true;
			this.paymentHistory = await PaymentHistory.getPaymentHistory(
				sessionStorage.getItem('email'),
				sessionStorage.getItem('apiToken'),
				this.$root.$i18n.locale,
				this.searchCriteria
			);
			this.isEmptyResults = !this.paymentHistory?.paymentLines?.length || false;
		}
		this.showLoading = false;
		this.hasloaded = true;
		this.$emit('showLoading', this.showLoading);
	}

	async goToDetailsPage(item) {
		this.$store.dispatch('updateLoading', true);
		let detailsSearchCriteria;
		if (this.searchCriteria?.fromDate) {
			detailsSearchCriteria = {
				paymentId: item.paymentId,
				fromDate: this.searchCriteria.fromDate,
				toDate: this.searchCriteria.toDate
			};
		} else {
			var tempSearchCriteria =
				(await PaymentHistory.getSavedSearchParameters(sessionStorage.getItem('email'))) || {};
			if (tempSearchCriteria?.fromDate) {
				detailsSearchCriteria = {
					paymentId: item.paymentId,
					fromDate: tempSearchCriteria.fromDate,
					toDate: tempSearchCriteria.toDate
				};
			} else {
				detailsSearchCriteria = {
					paymentId: item.paymentId,
					fromDate: format(
						new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1),
						'yyyy-MM-dd'
					),
					toDate: this.getToday()
				};
			}
		}
		let paymentDetails = await PaymentDetails.getPaymentDetails(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			detailsSearchCriteria
		);
		this.$store.dispatch('updateLoading', false);
		if (paymentDetails) {
			this.$router.push({ name: PAYMENT_HISTORY_DETAILS });
		} else {
			this.$store.dispatch('error');
		}
	}
}
</script>

<style lang="scss" scoped>
.payment-history-card {
	padding: 30px 15px;
}

.btn-history {
	float: right;
	@media (max-width: 768px) {
		float: left;
	}
}
.no-history {
	font-weight: 400;
	font-size: 18px;
	font-family: $josefin-font-family;
}
.svg-inline--fa {
	//need to remove the offset that is on by deault (easier to center)
	vertical-align: 0;
}
::v-deep .btn-history svg {
	font-size: 12px;
}
svg path {
	fill: $blue;
}
.payment-table {
	@include media-breakpoint-down(md) {
		& ::v-deep table {
			margin: 0;
		}
		& ::v-deep table tr {
			border-left: 0;
			border-right: 0;
			background-color: $white !important;
		}
		& ::v-deep table tr:last-child {
			border-bottom: 0;
		}
	}
}
</style>

<i18n>
{
	"en": {
		"title": "Payment History",
		"content": "Your payment history shows payments processed as of the last business day. This may not reflect all the claims we’re currently working on.",
		"button":	"View full payment history",
		"noHistory": "No payments recorded to your account in the last 6 months. ",
		"drugClaims": "Looking for drug claims?",
		"modal": {
			"text": "Prescription drug claims filed directly through your pharmacy can be found on the {claimsHistory} screen.",
			"link": "Claims History"
		}
	},
	"fr": {
		"title": "Historique des paiements",
		"content": "Votre historique des paiements affiche les demandes de règlement traitées en date du dernier jour ouvrable. Les demandes de règlement en processus de traitement pourraient ne pas y figurer.",
		"button":	"Voir l’historique des paiements complet",
		"noHistory": "Aucun paiement n’a été porté à votre compte au cours des six derniers mois. ",
		"drugClaims": "Vous cherchez des demandes de règlement pour médicaments?",
		"modal": {
			"text": "Cette page affiche les paiements traités en date du plus récent jour ouvrable. Les paiements ne sont pas tous affichés. Consultez la page {claimsHistory} de règlement pour voir les demandes de règlement de médicaments sur ordonnance soumises par votre pharmacie, ainsi que toutes les demandes de règlement qui datent de plus de deux ans.",
			"link": "Historique des demandes"
		}
	}
}
</i18n>
