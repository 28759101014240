<template>
	<BRow class="mb-4">
		<BCol v-if="isGetAnswerAvailable" cols="12" md="6" class="mb-4 mb-md-0">
			<CallToActionCard
				class="h-100"
				:icon="['far', 'question-circle']"
				:title="$t('commonQuestion.title')"
				:description="$t('commonQuestion.content')"
				:button-text="$t('commonQuestion.button')"
				automation-id="dashboard-get-answers"
				@click="toGetAnswers()"
			/>
		</BCol>
		<BCol cols="12" md="6">
			<CallToActionCard
				class="h-100"
				:icon="['fal', 'life-ring']"
				:title="$t('contactUs.title')"
				:description="$t('contactUs.content')"
				:button-text="$t('contactUs.button')"
				automation-id="dashboard-contact-us"
				@click="toContactUs()"
			/>
		</BCol>
	</BRow>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import ActionCards from '@/components/common/card/ActionCards';
import CallToActionCard from '@/components/common/card/CallToActionCard';
import { BRow, BCol } from 'bootstrap-vue';
import * as Routes from '@/constants/Routes';

@Component({
	name: 'DashboardBottomCards',
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			required: true
		}
	},
	components: {
		BRow,
		BCol,
		ActionCards,
		CallToActionCard
	}
})
export default class DashboardBottomCards extends Vue {
	toContactUs() {
		this.$router.push({ name: Routes.CONTACT_US });
	}

	get branding() {
		const { brand } = JSON.parse(sessionStorage.getItem('securityPolicy'));
		return brand;
	}

	get isGetAnswerAvailable() {
		if (this.branding === 'OBC' || this.branding === 'QBC') {
			return false;
		}
		return true;
	}

	toGetAnswers() {
		if (this.branding && this.branding === 'SBC') {
			window.open('https://www.sk.bluecross.ca/customer-service/faq/', '_blank');
		} else {
			if (this.$store.state.i18n.locale === 'en') {
				window.open('https://www.medaviebc.ca/en/help', '_blank');
			} else {
				window.open('https://www.medaviebc.ca/fr/aide', '_blank');
			}
		}
	}
}
</script>

<i18n>
{
  "en": {
    "commonQuestion": {
      "title": "Common Questions",
      "content": "Got a question you need answered or looking for more information?",
      "button": "Get answers"
		},
		"contactUs": {
			"title": "Need Help?",
      "content": "Can't find what you need online? Get in touch.",
      "button": "Contact Us"
		}
  },
  "fr": {
		"commonQuestion": {
      "title": "Questions courantes",
      "content": "Vous avez une question ou avez besoin de plus amples renseignements?",
      "button": "Obtenir une réponse"
		},
		"contactUs": {
			"title": "Besoin d'aide?",
      "content": "Vous ne trouvez pas ce que vous cherchez en ligne? Laissez-nous vous aider!",
      "button": "Contactez-nous"
		}
  }
}
</i18n>
