<template>
	<BCol class="mb-4">
		<BaseCard class="action-card mr-4" body-class="p-0 text-center">
			<BButton
				v-bind="$attrs"
				:aria-label="ariaLabel"
				variant="link"
				role="button"
				class="card-button"
				:size="size"
				:data-test-automation-id="getAutomationId('button')"
				:data-tracking-id="getAutomationId('button')"
				:disabled="disabled"
				@click="hasClicked($event)"
			>
				<FontAwesomeIcon
					aria-hidden="true"
					class="icon"
					:icon="icon"
					:data-test-automation-id="getAutomationId('base-drop-down-icon-1')"
				/>
				<br v-if="lineBreak" />
				<span class="action-card-text">
					{{ title }}
					{{ shortHand }}
				</span>
				<p v-if="description" class="description">
					{{ description }}
				</p>
			</BButton>
		</BaseCard>
	</BCol>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BButton, BRow, BCol } from 'bootstrap-vue';
// mixins
import IdMixin from '@/mixins/id';
// components
import BaseCard from '@/components/common/card/BaseCard.vue';

@Component({
	name: 'ActionButtonCard',
	components: {
		BButton,
		BRow,
		BCol,
		BaseCard
	},
	mixins: [IdMixin],
	props: {
		title: {
			type: String,
			default: ''
		},
		icon: {
			type: Array,
			default: () => []
		},
		ariaLabel: {
			type: String,
			default: ''
		},
		shortHand: {
			type: String,
			default: ''
		},
		// If this is set to true there will be a line break after the icon.
		// If false there will be no break.
		lineBreak: {
			type: Boolean,
			default: true
		},
		description: {
			type: String,
			default: null
		},
		disabled: {
			type: Boolean,
			default: false
		},
		size: {
			type: String,
			default: 'md'
		},
		automationId: {
			type: String,
			default: '',
			required: true
		}
	}
})
export default class ActionCard extends Vue {
	hasClicked(event) {
		this.$emit('click', event);
	}
}
</script>

<style lang="scss" scoped>
.action-card {
	width: 100%;
	padding: 0;
	height: 100%;
	box-shadow: 0px 0px 10px $shadow-grey;
	&:hover,
	&:focus,
	&:active,
	&:active:focus,
	&:active:hover {
		box-shadow: 0 0 10px $card-glow;
	}
}
.card-button {
	width: 100%;
	height: 100%;
	padding: 15px;
	font-size: 18px;
	text-decoration: none;
	border-radius: 10px;
	color: $blue;
	&:hover {
		color: $dark-blue;
	}
}
.description {
	font-family: 'lato', sans-serif;
	font-size: 17px;
	color: #1b1b1b;
	margin: 0.3em auto 0em 1.9em;
	font-weight: 400;
}
svg {
	font-size: 32px;
	color: $gray;
}
.icon {
	margin-bottom: 0.25rem;
}
</style>
