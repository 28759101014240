<!-- 
  Grid container for ActionCard components.  Built with css grid.
-->

<template>
	<div class="action-card-container">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'ActionCards'
};
</script>

<style lang="scss" scoped>
.action-card-container {
	margin: 15px 0;
	display: grid;
	// 1 column if can't fit 2 side-by-side that are 150px wide
	grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
	gap: 30px;
}

@media (min-width: 720px) {
	.action-card-container {
		grid-template-columns: repeat(3, 1fr); // 3 columns starting at medium sizes.
	}
}

@media (min-width: 992px) {
	.action-card-container {
		grid-template-columns: repeat(4, 1fr); // 4 columns starting at large sizes
	}
}
</style>
