<template>
	<BaseCard class="welcome-card" :automation-id="getAutomationId('welcome-member')">
		<h1 class="m-0 text-center text-sm-left">{{ $t('welcome', { name: memberName }) }}</h1>
		<div class="card-content d-flex flex-wrap justify-content-center justify-content-sm-start">
			<div>
				<p class="m-0">
					<b>{{ $t('cert') }}</b> {{ memberCerts }}
				</p>
			</div>
			<div>
				<p class="m-0">
					<b>{{ $t('policy') }}</b> {{ memberPol }}
				</p>
			</div>
			<div>
				<BaseButton
					:label="$t('button')"
					:icon="['fal', 'chevron-right']"
					variant="link"
					class="p-0 link-button"
					:automation-id="getAutomationId('welcome-member')"
					@click="toMemberLander()"
				/>
			</div>
		</div>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import { BRow, BCol } from 'bootstrap-vue';
import BaseCard from '@/components/common/card/BaseCard';
import BaseButton from '@/components/common/base/BaseButton';
import { MANAGE_YOUR_ACCOUNT } from '@/constants/Routes';

@Component({
	name: 'WelcomeMember',
	mixins: [IdMixin],
	components: {
		BRow,
		BCol,
		BaseCard,
		BaseButton
	},
	props: {
		memberInfo: {
			type: Object,
			required: true
		}
	}
})
export default class WelcomeMember extends Vue {
	get memberName() {
		return this.memberInfo.firstName;
	}

	get memberPol() {
		return this.memberInfo.policyId;
	}

	get memberCerts() {
		return this.memberInfo.certificateId;
	}

	get locale() {
		return this.$store.state.i18n.locale ? this.$store.state.i18n.locale : 'en';
	}

	toMemberLander() {
		this.$router.push({ name: MANAGE_YOUR_ACCOUNT });
	}
}
</script>

<style lang="scss" scoped>
.card-content {
	// needed for margin when wrapped
	& > div {
		margin-top: 10px;
	}
}
.welcome-card {
	font-size: 17px;
	width: fit-content;
	@include media-breakpoint-down(sm) {
		font-size: 15px;
		width: 100%;
	}
	& div:not(:last-child) {
		margin-right: 15px;
	}
}
::v-deep .link-button {
	font-size: 16px;
	& svg {
		font-size: 12px;
	}
}
</style>

<i18n>
{
  "en": {
    "welcome": "Welcome, {name}",
    "policy": "Policy:",
    "cert": "ID:",
    "button": "Manage my account"
  },
  "fr": {
    "welcome": "Bienvenue, {name}",
    "policy": "Nº de police :",
    "cert": "Nº d'identification :",
    "button": "Gérer votre compte "
  }
}
</i18n>
