import { render, staticRenderFns } from "./WelcomeMember.vue?vue&type=template&id=8dd3b4da&scoped=true&"
import script from "./WelcomeMember.vue?vue&type=script&lang=js&"
export * from "./WelcomeMember.vue?vue&type=script&lang=js&"
import style0 from "./WelcomeMember.vue?vue&type=style&index=0&id=8dd3b4da&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8dd3b4da",
  null
  
)

/* custom blocks */
import block0 from "./WelcomeMember.vue?vue&type=custom&index=0&blockType=i18n"
if (typeof block0 === 'function') block0(component)

export default component.exports