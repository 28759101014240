<template>
	<div>
		<BaseTable
			:items="preparedTableData"
			stacked="md"
			:per-page="perPage"
			:fields="getColumns()"
			small
			:class="['mt-2', 'claims-results']"
		>
			<template #cell(status)="row">
				<StatusButton
					v-if="isStatusButton(row.index)"
					:id="`${row.index}${row.value}`"
					:index="row.index"
					:array="claimsList"
					:data-tracking-id="`statusButton${row.index}`"
					:automation-id="getAutomationId(`statusButton${row.index}`)"
					:label="row.value"
				/>
			</template>
			<template #cell(details)="row">
				<BaseButton
					v-if="isDetailsButton(row.index)"
					:id="row.index"
					size="sm"
					variant="link"
					:pill="false"
					type="button"
					class="claims-details-button"
					:data-tracking-id="`claimDetails${row.index}`"
					:automation-id="getAutomationId(`claimDetail${row.index}`)"
					:label="$t('label.view')"
					@click="getDetails(row.item)"
				/>
			</template>
		</BaseTable>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseTable from '@/components/common/base/BaseTable';
import StatusButton from '@/components/claims-tracker/StatusButton';
import BaseButton from '@/components/common/base/BaseButton';
import PaymentDetails from '@/models/PaymentDetailsScepter';
import { PAYMENT_HISTORY_DETAILS } from '@/constants/Routes.js';

@Component({
	name: 'ClaimsTrackerTable',
	components: {
		BaseTable,
		StatusButton,
		BaseButton
	},
	props: {
		claimsList: {
			type: Array,
			default: () => []
		},
		totalAmount: {
			type: String,
			default: ''
		},
		perPage: {
			type: Number,
			default: 10
		}
	},
	mixins: [IdMixin]
})
export default class ClaimsTrackerTable extends Vue {
	isShowDetailsModal = false;
	isShowLinks = false;
	isShowViewButton = false;

	getColumns() {
		return [
			{
				key: 'receivedDate',
				label: this.$t('column.receivedDate'),
				thClass: 'th-received-class',
				tdClass: 'td-received-class'
			},
			{
				key: 'status',
				label: this.$t('column.status'),
				thClass: 'th-status-class',
				tdClass: 'td-statusd-class'
			},
			{
				key: 'claimType',
				label: this.$t('column.claimType'),
				thClass: 'th-claim-class',
				tdClass: 'td-claim-class'
			},
			{
				key: 'statusUpdate',
				label: this.$t('column.statusUpdate'),
				thClass: 'th-update-class',
				tdClass: 'td-update-class'
			},
			{
				key: 'details',
				label: this.$t('column.details'),
				thClass: 'th-details-class',
				tdClass: 'td-details-class'
			}
		];
	}
	get preparedTableData() {
		let preparedTableData = [];
		let index = 0;
		if (this.claimsList) {
			for (let claim of this.claimsList) {
				if (this.isEnglish) {
					preparedTableData.push({
						id: index,
						receivedDate: claim.receivedDate ? this.dateFormatted(claim.receivedDate, 'En') : null,
						statusUpdate: claim.statusUpdate ? this.dateFormatted(claim.statusUpdate, 'En') : null,
						status: claim.status,
						claimType: claim.claimType !== 'Both' ? claim.claimType : null
					});
				} else {
					preparedTableData.push({
						id: index,
						receivedDate: claim.receivedDate ? this.dateFormatted(claim.receivedDate, 'Fr') : null,
						statusUpdate: claim.statusUpdate ? this.dateFormatted(claim.statusUpdate, 'Fr') : null,
						status: claim.status,
						claimType: claim.claimType !== 'Both' ? claim.claimType : null
					});
				}
				index++;
			}
		}
		return preparedTableData;
	}

	get locale() {
		return this.$store.state.i18n.locale;
	}
	get isEnglish() {
		return this.$store.state.i18n.locale === 'en' ? true : false;
	}
	getToday() {
		return new Date().toLocaleDateString('en-CA');
	}
	dateFormatted(claimDate, locale) {
		var options = { day: '2-digit', month: 'short', year: 'numeric' };
		var date = claimDate.slice(0, 4) + '/' + claimDate.slice(4, 6) + '/' + claimDate.slice(6);
		const newDate = new Date(date);
		return locale === 'En'
			? newDate.toLocaleDateString('en-GB', options)
			: newDate.toLocaleDateString('fr-Fr', options);
	}
	isStatusButton(index) {
		let status = this.claimsList[index]?.status;
		return status ? true : false;
	}
	isDetailsButton(index) {
		let status = this.claimsList[index]?.status;
		if (
			this.claimsList[index]?.paymentId &&
			(status === this.$t('declined') || status === this.$t('processed'))
		) {
			return true;
		}
		return false;
	}
	async getDetails(item) {
		this.$store.dispatch('updateLoading', true);
		let detailsSearchCriteria;
		detailsSearchCriteria = {
			paymentId: this.claimsList[item.id].paymentId,
			fromDate: new Date(new Date().getFullYear(), new Date().getMonth() - 6, 1).toLocaleDateString(
				'en-CA'
			),
			toDate: this.getToday()
		};
		let paymentDetails = await PaymentDetails.getPaymentDetails(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$root.$i18n.locale,
			detailsSearchCriteria
		);
		this.$store.dispatch('updateLoading', false);
		if (paymentDetails) {
			this.$router.push({ name: PAYMENT_HISTORY_DETAILS, params: { isClaims: true } });
		} else {
			this.$store.dispatch('error');
		}
	}
}
</script>
<style lang="scss" scoped>
.claims-results ::v-deep tr:nth-child(even) {
	background-color: $gray-very-light;
}
.claims-details-button {
	height: 25px;
	padding: 0 0;
	font-size: 15px;
	font-family: 'Lato', sans-serif;
	@media (max-width: 1000px) {
		white-space: nowrap;
	}
}
</style>

<i18n>
{
  "en": {
	"column": {
		"receivedDate": "Received date",
		"status": "Status",
		"claimType": "Claim type",
        "statusUpdate": "Status update",
		"details": "Details"
	},
	"label": {
		"view": "View >"
	},
	"processed": "Processed",
	"declined": "Declined"
  },
  "fr": {
	"column": {
		"receivedDate": "Reçue le",
		"status": "État",
		"claimType": "Type de demande de règlement",
        "statusUpdate": "Mise à jour de l’état",
		"details": "Détails"
	},
	"label": {
		"view": "Consulter >"
	},
	"processed": "Traitée",
	"declined": "Refusée"
  }
}
</i18n>
