<template>
	<BaseModal
		automation-id="claimsStatusInfoModal"
		:modal-show="modalShow"
		size="md"
		class="claims-info-modal"
		@change="change"
	>
		<template #contentBody>
			<p class="info-modal-title">{{ $t('title') }}</p>
			<div v-for="(item, index) in statusList" :key="index">
				<BaseButton
					:variant="item.variant"
					class="status-button btn-status-info"
					size="sm"
					icon-position="left"
					data-tracking-id="`infoModalStatusButtons${item.index}`"
					:automation-id="getAutomationId(`infoModalStatusButtons${item.index}`)"
					:pill="false"
					:label="item.label"
					:icon="item.icon"
					disabled
				/>
				<p class="paragraph-title mt-2">{{ item.title }}</p>
				<p class="paragraph-text">{{ item.text }}</p>
			</div>
			<BaseButton
				class="close-button"
				variant="primary"
				data-tracking-id="infoModalCloseButton"
				:automation-id="getAutomationId('infoModalCloseButton')"
				:label="$t('close')"
				:aria-label="$t('close')"
				@click="change"
			/>
		</template>
	</BaseModal>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseModal from '@/components/common/base/BaseModal.vue';

/**
 * This component renders a claims status info modal in claims tracker table.
 */

@Component({
	name: 'StatusInfoModal',
	components: {
		BaseModal,
		BaseButton
	},
	mixins: [IdMixin],
	props: {
		modalShow: {
			type: Boolean,
			default: false
		}
	}
})
export default class StatusInfoModal extends Vue {
	statusList = [
		{
			variant: 'outline-dark',
			label: this.$t('submitted.label'),
			title: this.$t('submitted.title'),
			text: this.$t('submitted.text'),
			icon: ['fas', 'arrow-circle-right']
		},
		{
			variant: 'outline-primary',
			label: this.$t('inProgress.label'),
			title: this.$t('inProgress.title'),
			text: this.$t('inProgress.text'),
			icon: ['fas', 'spinner']
		},
		{
			variant: 'outline-warning',
			label: this.$t('pending.label'),
			title: this.$t('pending.title'),
			text: this.$t('pending.text'),
			icon: ['far', 'clock']
		},
		{
			variant: 'outline-success',
			label: this.$t('processed.label'),
			title: this.$t('processed.title'),
			text: this.$t('processed.text'),
			icon: ['fal', 'check']
		},
		{
			variant: 'outline-danger',
			label: this.$t('declined.label'),
			title: this.$t('declined.title'),
			text: this.$t('declined.text'),
			icon: ['fas', 'exclamation-triangle']
		}
	];
	get locale() {
		return this.$store.state.i18n.locale;
	}
	change(isVisible) {
		this.$emit('change', isVisible);
	}
}
</script>

<style lang="scss" scoped>
.btn-status-info {
	font-size: 12px;
	height: 25px;
	padding: 3px 6px 0 6px;
	&:disabled {
		opacity: 1;
	}
	&.btn-outline-dark {
		color: $black;
		border-color: $black;
	}
}
.info-modal-title {
	// use negative margins to adjust modal title with closing button as using title inside contentBody template
	margin-top: -49px;
	font-family: $font-family-headline;
	font-size: 22px;
	color: $black;
	width: 70%;
}

.paragraph-title {
	font-size: 17px;
	font-weight: bold;
	margin-bottom: 0;
}
.paragraph-text {
	margin-bottom: 20px;
}
</style>
<i18n>
{
  "en": {
		"close": "Close",
		"title": "Claim status legend",
		"submitted": {
			"label": "Submitted",
			"title": "You recently submitted this claim.",
			"text": "Your claim has been received. You will hear from us when your claim has been processed. No further action is required at this time."
		},
		"inProgress": {
			"label": "In Progress",
			"title": "Your claim is being reviewed.",
			"text": "Our claims analysts are processing your claim."
		},
		"pending": {
			"label": "Pending",
			"title": "Additional verification is required.",
			"text": "We are diligently working on your claim. Thank you for your patience."
		},
		"processed": {
			"label": "Processed",
			"title": "Your claim has been reviewed.",
			"text": "The Payment Details screen has more information. If a payment has been issued, it will appear on your statement within 7 business days."
		},
		"declined": {
			"label": "Declined",
			"title": "We couldn’t approve your claim at this time.",
			"text": "The Payment Details screen has more information. If your claim was missing information, please submit it again with all required documentation."
		}
  },
  "fr": {
		"close": "Fermer",
		"title": "Légende - État de la demande de règlement",
		"submitted": {
			"label": "Soumise",
			"title": "Vous avez récemment soumis cette demande de règlement.",
			"text": "Nous avons reçu votre demande de règlement. Vous aurez de nos nouvelles dès que votre demande de règlement sera traitée. Aucune autre action n'est requise."
		},
		"inProgress": {
			"label": "En traitement",
			"title": "Votre demande de règlement est en cours d’examen.",
			"text": "Nos analystes des demandes de règlement traitent votre demande de règlement."
		},
		"pending": {
			"label": "En attente",
			"title": "Une vérification supplémentaire est nécessaire.",
			"text": "Nous travaillons au traitement de votre demande de règlement. Merci de votre patience."
		},
		"processed": {
			"label": "Traitée",
			"title": "Votre demande de règlement a été examinée.",
			"text": "La page Détails sur le paiement contient plus d'informations. Si un paiement a été émis, il s'affichera sur votre relevé d'ici 7 jours ouvrables."
		},
		"declined": {
			"label": "Refusée",
			"title": "Nous n’avons pas pu approuver votre demande de règlement pour le moment.",
			"text": "La page Détails sur le paiement contient plus d'informations. S'il manque des renseignements dans votre demande de règlement, veuillez la soumettre à nouveau avec tous les documents exigés."
		}
    }
}
</i18n>
