<template>
	<div>
		<slot></slot>
	</div>
</template>

<script>
import IdMixin from '@/mixins/id';

export default {
	name: 'ActionButtonCardContainer',
	mixins: [IdMixin],
	inheritAttrs: false,
	props: {}
};
</script>
