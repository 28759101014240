<template>
	<div>
		<BaseTable
			v-if="stacked !== 'all'"
			:items="historyTableItems()"
			:fields="historyHeaders"
			:stacked="stacked"
			small
			:class="{ 'mb-0': !bottomMargin }"
			:top-header-items="historyTableHeaders()"
			:automation-id="getAutomationId(automationId)"
		>
			<template #table-caption>
				<div v-if="topBarItems.length > 0" class="pt-3 px-3 top-bar d-flex">
					<div class="flex-grow-1">{{ topBarItems[0] }}</div>
					<div v-if="topBarItems[1]">
						{{ topBarItems[1] }}
					</div>
				</div>
			</template>
			<!-- header-->
			<template #head(dateProcessed)="data">
				<span
					><template v-for="(word, index) in data.label.split(' ')">
						<template v-if="index === data.label.split(' ').length - 1"
							><span :key="word" class="text-nowrap"
								>{{ word }}
								<InfoModal
									class="d-inline"
									:automation-id="getAutomationId('date-processed-modal-history-table')"
									:button-padding="false"
									:modal-title="t('dateProcessedModal.title')"
								>
									{{ t('dateProcessedModal.text') }}
								</InfoModal>
							</span></template
						>
						<template v-else>{{ word }} </template>
					</template>
				</span>
			</template>
			<template #head(submittedAmount)="data"
				><div class="text-right">{{ data.label }}</div></template
			>
			<template #head(eligibleAmount)="data"
				><div class="text-right">{{ data.label }}</div></template
			>
			<template #head(excludedAmount)="data">
				<div class="text-right">
					<template v-for="(word, index) in data.label.split(' ')">
						<template v-if="index === data.label.split(' ').length - 1"
							><span :key="word" class="text-nowrap"
								>{{ word }}
								<InfoModal
									class="d-inline"
									:automation-id="getAutomationId('excluded-amount-modal-history-table')"
									:button-padding="false"
									:modal-title="t('excludedAmountModal.title')"
								>
									{{ t('excludedAmountModal.text') }}
								</InfoModal>
							</span></template
						>
						<template v-else>{{ word }} </template>
					</template>
				</div>
			</template>
			<template #head(paidAmount)="data"
				><div class="text-right">{{ data.label }}</div></template
			>
			<template #head(coinsurancePercent)="data">
				<div class="text-right">
					<template v-for="(word, index) in data.label.split(' ')">
						<template v-if="index === data.label.split(' ').length - 1"
							><span :key="word" class="text-nowrap"
								>{{ word }}
								<InfoModal
									class="d-inline"
									:automation-id="getAutomationId('percent-covered-modal-history-table')"
									:button-padding="false"
									:modal-title="t('percentageCoveredModal.title')"
								>
									{{ t('percentageCoveredModal.text') }}
								</InfoModal>
							</span></template
						>
						<template v-else>{{ word }} </template>
					</template>
				</div>
			</template>
			<template #head(hasPaymentDetails)="data">
				<template v-for="(word, index) in data.label.split(' ')">
					<template v-if="index === data.label.split(' ').length - 1"
						><span :key="word" class="text-nowrap"
							>{{ word }}
							<InfoModal
								class="d-inline"
								:button-padding="false"
								:automation-id="getAutomationId('statement-modal-history-table')"
								:modal-title="t('statementModal.title')"
							>
								{{ t('statementModal.text') }}
							</InfoModal>
						</span></template
					>
					<template v-else>{{ word }} </template>
				</template>
			</template>
			<!-- cells -->
			<template #cell(dateProcessed)="data">
				{{ formatDate(data.value) }}
			</template>
			<template #cell(serviceDate)="data">
				{{ formatDate(data.value) }}
			</template>
			<template #cell(serviceInfo)="data">
				{{ data.value.description }}
			</template>
			<template #cell(submittedAmount)="data">
				<div class="text-lg-right">{{ formatCurrency(data.value) }}</div>
			</template>
			<template #cell(eligibleAmount)="data">
				<div class="text-lg-right">{{ formatCurrency(data.value) }}</div>
			</template>
			<template #cell(excludedAmount)="data">
				<div class="text-lg-right">{{ formatCurrency(data.value) }}</div>
			</template>
			<template #cell(paidAmount)="data">
				<div class="text-lg-right">{{ formatCurrency(data.value) }}</div>
			</template>
			<template #cell(coinsurancePercent)="data">
				<!-- eslint-disable-next-line -->
				<div class="text-lg-right">{{ data.value }}%</div>
			</template>
			<template #cell(hasPaymentDetails)="row">
				<BaseButton
					v-if="row.item.hasPaymentDetails === true"
					variant="link"
					:pill="false"
					type="button"
					class="p-0"
					:data-tracking-id="`statementResult${row.index}`"
					:automation-id="getAutomationId(`statementResult${row.index}`)"
					:label="t('view')"
					@click="goToDetails(row.item.claimId)"
				/>
				<template v-else>{{ t('unavailable') }}</template>
			</template>
			<template #cell(eobs)="data">
				<b v-for="(eob, index) in data.value" :key="eob.eobCode">
					{{ eob.eobCode }}<span v-if="index !== data.value.length - 1">, </span>
				</b>
			</template>
			<template #custom-foot>
				<!-- EOB EXPLANATIONs -->
				<template v-if="showEobExplanation && historyFields.includes('eobs') && eobs.length > 0">
					<BTr>
						<BTh colspan="6" class="invisible"></BTh>
					</BTr>
					<BTr>
						<BTd :colspan="historyHeaders.length" class="bg-white p-3">
							<div class="josefin font-17">{{ t('eob') }}</div>
							<div v-for="item in eobs" :key="item.eobCode" class="font-17 mt-1">
								<!-- eslint-disable-next-line -->
								<b>{{ item.eobCode }}</b> - {{ item.eobMessage }}
							</div>
						</BTd>
					</BTr>
				</template>

				<!-- TOTALS -->
				<BTr v-if="historyFields.includes('eobs')">
					<BTh :colspan="historyHeaders.length" class="invisible"></BTh>
				</BTr>
				<BTr
					v-if="showTotals"
					:class="['table-footer', { rounded: historyFields.includes('eobs') }]"
				>
					<BTd
						v-for="(item, index) in historyFields.filter((field) =>
							historyHeaders.find((header) => header.key === field)
						)"
						:key="item.key"
					>
						<div v-if="index === 0" class="text-left">
							{{ historyFields.includes('eobs') ? t('subtotal') : t('total') }}
						</div>
						<div v-else-if="totals.find((gt) => gt.key === item)" class="text-right">
							<span
								:class="{
									'font-light': historyFields.includes('eobs') && item !== 'paidAmount'
								}"
								>{{ formatCurrency(totals.find((gt) => gt.key === item).value) }}
							</span>
						</div>
					</BTd>
				</BTr>

				<!-- GRAND TOTALS -->
				<template v-if="showGrandTotals">
					<BTr>
						<BTh :colspan="historyHeaders.length" class="invisible"></BTh>
					</BTr>
					<BTr class="grand-total">
						<BTd
							v-for="(item, index) in historyFields.filter((field) =>
								historyHeaders.find((header) => header.key === field)
							)"
							:key="item.key"
						>
							<div
								v-if="index === 0"
								:class="['text-left', { 'font-17': historyFields.includes('eobs') }]"
							>
								{{ historyFields.includes('eobs') ? t('total') : t('grandTotal') }}
							</div>
							<div
								v-else-if="grandTotals.find((gt) => gt.key === item) && item !== 'claimId'"
								class="text-right"
							>
								{{ formatCurrency(grandTotals.find((gt) => gt.key === item).value) }}
							</div>
						</BTd>
					</BTr>
				</template>
			</template>
		</BaseTable>

		<!-- stacked table. we need this because of tooltips -->
		<table
			:class="[
				`table b-table table-sm b-table-stacked stacked-table m-0`,
				`d-${stacked}-none`,
				{ 'mb-3': showTotals || showGrandTotals }
			]"
			role="table"
			aria-busy="false"
			:data-test-automation-id="getAutomationId(`${automationId}-stacked`)"
		>
			<caption>
				<BRow v-if="topBarItems.length > 0" class="pt-3 m-0 top-bar">
					<BCol sm="12" md="6">{{ topBarItems[0] }}</BCol>
					<BCol
						v-if="topBarItems[1]"
						class="text-md-right d-flex justify-content-md-end"
						sm="12"
						md="6"
					>
						{{ topBarItems[1] }}
					</BCol>
				</BRow>
			</caption>
			<tbody role="rowgroup">
				<tr
					v-for="(item, index) in historyStackedItems()"
					:key="index"
					role="row"
					:class="{
						'last-rounded mb-3':
							index === historyStackedItems().length - 1 && topBarItems.length > 0
					}"
				>
					<td v-for="entry in item" :key="entry.key" :aria-colindex="index + 1" role="cell">
						<div class="d-flex w-100">
							<label class="w-50 text-right text-bold m-0 pr-2">
								{{ entry.header }}
								<template
									v-if="
										[
											'dateProcessed',
											'hasPaymentDetails',
											'excludedAmount',
											'coinsurancePercent'
										].includes(entry.key)
									"
								>
									<InfoModal
										class="d-inline"
										:button-padding="false"
										:modal-title="modalInfo(entry.key).title"
										:automation-id="getAutomationId(`${entry.key}-modal`)"
									>
										{{ modalInfo(entry.key).text }}
									</InfoModal>
								</template>
							</label>
							<div class="w-50 pl-2">
								<template
									v-if="
										['paidAmount', 'submittedAmount', 'eligibleAmount', 'excludedAmount'].includes(
											entry.key
										)
									"
								>
									{{ formatCurrency(entry.value) }}
								</template>
								<template v-else-if="['dateProcessed', 'serviceDate'].includes(entry.key)">
									{{ formatDate(entry.value) }}
								</template>
								<template v-else-if="['coinsurancePercent'].includes(entry.key)">
									<!-- eslint-disable-next-line -->
									<span>{{ entry.value }}%</span>
								</template>
								<template v-else-if="['eobs'].includes(entry.key)">
									<span v-for="(eob, eobIndex) in entry.value" :key="eob.eobCode">
										<b :key="eob.eobCode"> {{ eob.eobCode }}</b>
										<span v-if="eobIndex !== entry.value.length - 1">, </span>
									</span>
								</template>
								<template v-else-if="entry.key === 'hasPaymentDetails'">
									<BaseButton
										v-if="entry.value === true"
										variant="link"
										:pill="false"
										type="button"
										class="p-0"
										:data-tracking-id="`statementResult${entry.key}`"
										:automation-id="`statementResult${entry.key}`"
										:label="t('view')"
										@click="goToDetails(item.claimId.value)"
									/>
									<template v-else>{{ t('unavailable') }}</template>
								</template>
								<template v-else-if="entry.key === 'serviceInfo'">
									{{ entry.value.description }}
								</template>
								<template v-else-if="entry.header">{{ entry.value }}</template>
							</div>
						</div>
					</td>
				</tr>
				<template v-if="showEobExplanation && historyFields.includes('eobs') && eobs.length > 0">
					<tr class="bg-white rounded">
						<td :colspan="historyHeaders.length">
							<div class="josefin font-17">{{ t('eob') }}</div>
							<div v-for="item in eobs" :key="item.eobCode" class="font-17 mt-1">
								<!-- eslint-disable-next-line -->
								<b>{{ item.eobCode }}</b> - {{ item.eobMessage }}
							</div>
						</td>
					</tr>
				</template>
			</tbody>
		</table>

		<!-- stacked totals footer for mobile -->
		<BaseTable
			v-if="showTotals"
			:class="['stacked-footer d-block', `d-${stacked}-none`]"
			:stacked="stacked === 'all' ? true : stacked"
			small
			:fields="[
				props.historyFields.includes('eobs') ? t('subtotal') : t('total'),
				...totals.map((item) => item.key)
			]"
			:items="stackedTotals()"
			:automation-id="getAutomationId(`${automationId}-stacked-footer1`)"
		>
		</BaseTable>

		<!-- stacked grand totals footer for mobile -->
		<BaseTable
			v-if="showGrandTotals"
			:class="['stacked-footer grand-total-stacked d-block', `d-${stacked}-none`]"
			small
			:stacked="stacked === 'all' ? true : stacked"
			:fields="[
				props.historyFields.includes('eobs') ? t('total') : t('grandTotal'),
				...grandTotals.map((item) => item.key)
			]"
			:items="stackedGrandTotals()"
			:automation-id="getAutomationId(`${automationId}-stacked-footer2`)"
		>
		</BaseTable>
	</div>
</template>

<script setup>
import { defineProps, getCurrentInstance } from 'vue';
import { format, parseISO } from 'date-fns';
import { enCA, frCA } from 'date-fns/esm/locale';
import { useI18n } from '@/composables/useI18n';
import { BRow, BCol, BTr, BTd, BTh } from 'bootstrap-vue';
// components
import BaseTable from '@/components/common/base/BaseTable.vue';
import InfoModal from '@/components/common/InfoModal.vue';
import BaseButton from '@/components/common/base/BaseButton.vue';
// routes
import { HISTORY_DETAILS } from '@/constants/Routes';
// composables
import { useComponentId } from '@/composables/useComponentId';

const { t } = useI18n();
const root = getCurrentInstance().proxy.$root;
const { getAutomationId } = useComponentId();

const props = defineProps({
	participantInfo: {
		type: Object,
		default: null
	},
	historyFields: {
		type: Array,
		default: () => [
			'benefitCode',
			'dateProcessed',
			'serviceDate',
			'serviceInfo',
			'submittedAmount',
			'paidAmount',
			'deductibleAmount',
			'excludedAmount',
			'paymentMethod',
			'claimId'
		]
	},
	totalFields: {
		type: Array,
		default: () => []
	},
	historyHeaders: {
		type: Array,
		required: true
	},
	history: {
		type: Array,
		required: true
	},
	topBarItems: {
		type: Array,
		default: () => []
	},
	showTotals: {
		type: Boolean,
		default: false
	},
	showGrandTotals: {
		type: Boolean,
		default: false
	},
	showEobExplanation: {
		type: Boolean,
		default: false
	},
	totalSubmittedAmount: {
		type: Number,
		default: 0
	},
	totalPaidAmount: {
		type: Number,
		default: 0
	},
	grandTotalSubmittedAmount: {
		type: Number,
		default: 0
	},
	grandTotalPaidAmount: {
		type: Number,
		default: 0
	},
	bottomMargin: {
		type: Boolean,
		default: true
	},
	stacked: {
		type: String,
		default: 'md',
		validator: (prop) => ['xs', 'sm', 'md', 'lg', 'xl', 'all'].includes(prop)
	},
	eobs: {
		type: Array,
		default: () => []
	},
	grandTotals: {
		type: Array,
		default: () => []
	},
	totals: {
		type: Array,
		default: () => []
	},
	automationId: {
		type: String,
		default: 'history-table'
	}
});

// methods
function historyTableHeaders() {
	if (!props.participantInfo) return null;
	return [
		`${props.participantInfo.firstName} ${props.participantInfo.lastName}`,
		`${t('dob')} ${formatDate(props.participantInfo.dateOfBirth)}`
	];
}
function historyTableItems() {
	// filter data from API
	return props.history
		.map((obj) => {
			const filteredItems = {};
			props.historyFields.forEach((field) => {
				filteredItems[field] = obj[field];
			});
			return filteredItems;
		})
		.flat();
}
function historyStackedItems() {
	let items = historyTableItems();
	items = items.map((i) => {
		const obj = {};
		for (const [key, value] of Object.entries(i)) {
			const header = props.historyHeaders.find((h) => h.key === key)?.label;
			obj[key] = { key, header, value };
		}
		return obj;
	});
	return items;
}
function formatDate(date) {
	return format(new Date(parseISO(date)), 'dd MMM yyyy', {
		locale: root.$i18n.locale === 'fr' ? frCA : enCA
	});
}
function formatCurrency(number) {
	return number?.toLocaleString(root.$i18n.locale === 'fr' ? 'fr-CA' : 'en-CA', {
		style: 'currency',
		currency: 'CAD'
	});
}
function goToDetails(claimId) {
	root.$router.push({ name: HISTORY_DETAILS, query: { 'claim-id': claimId } });
}
function modalInfo(key) {
	switch (key) {
		case 'coinsurancePercent':
			return { title: t('percentageCoveredModal.title'), text: t('percentageCoveredModal.text') };
		case 'excludedAmount':
			return { title: t('excludedAmountModal.title'), text: t('excludedAmountModal.text') };
		case 'dateProcessed':
			return { title: t('dateProcessedModal.title'), text: t('dateProcessedModal.text') };
		case 'hasPaymentDetails':
			return { title: t('statementModal.title'), text: t('statementModal.text') };
	}
}
function stackedTotals() {
	const title = props.historyFields.includes('eobs') ? t('subtotal') : t('total');
	return [
		{
			[title]: '',
			...Object.fromEntries(props.totals.map((item) => [item.key, formatCurrency(item.value)]))
		}
	];
}
function stackedGrandTotals() {
	const title = props.historyFields.includes('eobs') ? t('total') : t('grandTotal');
	return [
		{
			[title]: '',
			...Object.fromEntries(props.grandTotals.map((item) => [item.key, formatCurrency(item.value)]))
		}
	];
}
</script>

<style lang="scss" scoped>
.text-right {
	white-space: normal;
}
::v-deep caption {
	padding-top: 0;
	padding-bottom: 0;
}
.top-bar {
	background-color: $primary;
	border-radius: 10px 10px 0px 0px;
	height: 100%;
	color: $white;
	padding: 10px 0;
	& :first-child {
		font-size: 21px;
		font-family: $josefin-font-family;
	}
}
::v-deep thead th {
	// this is needed because of the blue caption header
	border-radius: 0 !important;
}
/* stylelint-disable no-descending-specificity */
// disable stylelint here because these two classes will never be applied at the same time
::v-deep .b-table.stacked {
	display: none;
}
::v-deep .stacked-table {
	& td {
		border-top: none !important;
	}
	& tr {
		padding: 15px;
		border: 1px solid $gray-light;
		border-bottom: none;
		background-color: $white;
	}
	& .last-rounded {
		border-bottom: 1px solid $gray-light;
		border-radius: 0 0 5px 5px;
	}
	& tr:nth-child(even) {
		background-color: $gray-very-light;
	}
}
.table-footer {
	background-color: transparent !important;
	&.rounded td {
		&:first-of-type {
			border-radius: 5px 0 0 5px !important;
		}
		&:last-of-type {
			border-radius: 0 5px 5px 0 !important;
		}
	}
	& td {
		font-family: $lato-font-family;
		font-weight: bold;
		font-size: 15px;
		border-top: none;
		color: $black;
		background-color: #e4e4e4;
		&:first-of-type {
			font-family: $josefin-font-family;
			font-size: 18px;
			font-weight: normal;
			border-radius: 0 0 0 5px !important;
		}
		&:last-of-type {
			border-radius: 0 0 5px 0 !important;
		}
	}
}
::v-deep .grand-total {
	& td {
		background-color: $dark-blue;
		color: $white;
		font-family: $lato-font-family;
		font-weight: bold;
		font-size: 15px;
		&:first-of-type {
			font-family: $josefin-font-family;
			font-size: 18px;
			font-weight: normal;
			border-radius: 5px 0 0 5px;
		}
		&:last-of-type {
			border-radius: 0 5px 5px 0;
		}
	}
}
::v-deep .stacked-footer {
	&.no-stack {
		& td {
			border: none !important;
		}
	}
	& tr {
		background-color: #e4e4e4;
		border-radius: 5px !important;
		border: none;
		& td {
			font-family: $lato-font-family;
			font-weight: bold;
			font-size: 15px;
			&::before {
				font-family: $josefin-font-family;
				font-size: 18px;
				font-weight: normal !important;
				border-radius: 0 0 0 5px !important;
			}
		}
	}
	&.grand-total-stacked {
		& tr {
			background-color: $dark-blue;
			color: $white;
		}
	}
}
/* stylelint-enable no-descending-specificity */
</style>

<i18n lang="json">
{
	"en": {
		"view": "View >",
		"unavailable": "Unavailable",
		"dob": "Date of Birth:",
		"total": "Total",
		"subtotal": "Subtotal",
		"grandTotal": "Grand Total",
		"eob": "Explanation of Benefits (EOB)",
		"dateProcessedModal": {
			"title": "What does this mean?",
			"text": "\"Date processed\" refers to the date that we processed your submitted claim and issued your payment. If you've chosen to be reimbursed through direct deposit, it can take up to 7 business days for your bank to deposit your money."
		},
		"excludedAmountModal": {
			"title": "Not sure why some of your payment was excluded?",
			"text": "Some of your claim amount may have been excluded because it exceeded the maximum amount for this claim type, or because it was covered by another provider. The Explanation of Benefits may have more specific details about how we calculated this payment."
		},
		"statementModal": {
			"title": "What's on my statement?",
			"text": "The payment statement includes important details on the processing of your claims, including the amount paid and any payment exclusions. You won't see a statement for drug claims submitted by your pharmacy."
		},
		"percentageCoveredModal": {
			"title": "What is percentage covered?",
			"text": "Your co-insurance percentage is the percentage of the claim that your plan will cover. For example, if you submit a claim and Blue Cross will cover 80% of costs associated, your co-insurance percentage will say: 80%."
		}
	},
	"fr": {
		"view": "Afficher >",
		"unavailable": "Non disponible",
		"dob": "Date de naissance:",
		"total": "Total",
		"subtotal": "Sous-total",
		"grandTotal": "Total général",
		"eob": "Explication du remboursement",
		"dateProcessedModal": {
			"title": "Qu'est-ce que cela signifie?",
			"text": "La « date de traitement » est la date à laquelle nous avons traité votre demande de règlement et émis votre paiement. Si vous avez opté pour le remboursement par dépôt direct, il faut compter jusqu'à 7 jours ouvrables pour que votre banque dépose votre argent."
		},
		"excludedAmountModal": {
			"title": "Pourquoi une partie de votre paiement est-elle exclue?",
			"text": "Une partie du montant de votre demande de règlement a peut-être été exclue parce que le montant dépassait le maximum admissible de ce type de demandes de règlement ou parce qu'il a déjà été remboursé par un autre assureur. Vous pouvez consulter l'Explication du remboursement pour en savoir plus sur le calcul exact des prestations."
		},
		"statementModal": {
			"title": "Qu'est-ce qui se trouve sur mes relevés?",
			"text": "Vos relevés de paiement contiennent des renseignements importants sur le traitement de vos demandes de règlement, y compris le montant payé et d'éventuelles exclusions de paiement. Les demandes de règlement de médicaments payées par les pharmacies ne sont pas affichées."
		},
		"percentageCoveredModal": {
			"title": "Qu'est-ce que le pourcentage couvert?",
			"text": "Le pourcentage de coassurance est le pourcentage de la demande de règlement que votre régime rembourse. Par exemple, si vous soumettez une demande de règlement et que Croix Bleue rembourse 80 % des coûts qui lui sont associés, votre pourcentage de coassurance indique 80 %. "
		}
	}
}
</i18n>
