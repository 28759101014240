<template>
	<BaseCard
		class="connected-care-card"
		body-class="p-0 m-0 d-flex flex-wrap flex-lg-nowrap justify-content-between"
		:autoation-id="getAutomationId('connect-care')"
	>
		<div>
			<img src="@/assets/connected-care.jpeg" :alt="$t('title')" class="connected-care-img" />
		</div>
		<div class="align-self-center">
			<div class="px-4 py-4 py-lg-0">
				<p class="h3">{{ $t('title') }}</p>
				<p class="content">{{ $t('content') }}</p>
				<BaseButton
					:label="$t('button')"
					:icon="['fal', 'external-link']"
					:automation-id="getAutomationId('connected-care-learn-more')"
					@click="openTab()"
				/>
			</div>
		</div>
	</BaseCard>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
// mixins
import IdMixin from '@/mixins/id';
// components
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
// models
import Authentication from '@/models/Authentication';

@Component({
	name: 'ConnectedCarCard',
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			required: true
		}
	},
	components: {
		BRow,
		BCol,
		BaseCard,
		BaseButton
	}
})
export default class ConnectedCareCard extends Vue {
	connectedCareToken = null;

	async created() {
		this.connectedCareToken = await Authentication.getConnectedCareToken(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
	}

	openTab() {
		if (this.$store.state.i18n.locale === 'en') {
			window.open(
				`https://www.medaviebc.ca/connected-care?msf_token=${this.connectedCareToken}`,
				'_blank'
			);
		} else {
			window.open(
				`https://www.medaviebc.ca/fr/connexion-sante?msf_token=${this.connectedCareToken}`,
				'_blank'
			);
		}
	}
}
</script>
<style lang="scss" scoped>
.connected-care-card {
	overflow: hidden;
	& ::v-deep > div > div {
		width: 100%;
		& img {
			width: 100%;
			max-height: 250px;
		}
	}
}
</style>
<i18n>
{
  "en": {
    "title": "Connected Care",
    "content": "As a Medavie Blue Cross member, you can enjoy exclusive access and preferred pricing for innovative, convenient health care options.",
    "button": "Learn more"
  },
  "fr": {
        "title": "Connexion santé",
    "content": "Les adhérents de Croix Bleue Medavie ont un accès exclusif à des options de soins de santé novatrices et pratiques, à des prix préférentiels.",
    "button": "En savoir plus"
  }
}
</i18n>
