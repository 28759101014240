<template>
	<div
		class="claims-tracker-card"
		body-class="py-3 py-md-4"
		:automation-id="getAutomationId('claim-tracker')"
	>
		<div v-if="hasLoaded">
			<p class="h4 mb-1">{{ $t('title') }}</p>
			<p class="mt-0">{{ $t('content') }}</p>
		</div>
		<BRow v-if="!isEmptyResults && hasLoaded">
			<BCol class="px-0 px-md-3">
				<ClaimsTrackerTable
					class="payment-table"
					:claims-list="claims"
					:per-page="perPage"
					:requires-label="false"
					:requires-pagination="false"
					:automation-id="getAutomationId('claims-tracker-table')"
				/>
			</BCol>
		</BRow>
		<BRow v-if="displayNoClaims && hasLoaded">
			<BCol>
				<p class="no-claims mb-3">{{ $t('noClaims') }}</p>
			</BCol>
		</BRow>
		<BRow v-if="displayError">
			<BCol>
				<p class="no-claims mb-3">{{ $t('claimsError') }}</p>
			</BCol>
		</BRow>
		<BRow v-if="showLoadMoreButton && hasLoaded">
			<BCol class="claimHystoryLink mt-1 mb-3">
				<BaseButton
					:label="$t('loadMore')"
					:automation-id="getAutomationId('loadMoreButton')"
					@click="showMore"
				/>
			</BCol>
		</BRow>
		<BRow v-if="hasLoaded">
			<BCol>
				<BaseButton
					class="p-1 pt-3 pt-lg-0"
					variant="link"
					automation-id="drug-claims-modal"
					:label="$t('drugClaims')"
					:icon="['fal', 'question-circle']"
					@click="showModal = true"
				/>
			</BCol>
		</BRow>
		<BaseModal
			:modal-show="showModal"
			:modal-title="$t('drugClaims')"
			size="md"
			automation-id="drug-claims-modal"
			@change="showModal = false"
		>
			<template #contentBody>
				<i18n path="modal.text" tag="span">
					<template #claimsHistory>
						<router-link :to="{ name: claimHistoryLink }">
							<strong>{{ $t('modal.link') }}</strong>
						</router-link>
					</template>
				</i18n>
			</template>
		</BaseModal>
		<hr v-if="hasLoaded" />
		<BRow v-if="hasLoaded">
			<BCol class="claimHystoryLink">
				<BaseButton
					class="px-0 btn-history"
					:label="$t('claimHistoryButton')"
					variant="link"
					:automation-id="getAutomationId('fullClaimHistory')"
					@click="toFullClaimHistory"
				/>
			</BCol>
		</BRow>
		<BRow v-if="showLoading">
			<BCol cols="12" class="text-center">
				<FontAwesomeIcon
					spin
					class="loading-icon text-center"
					:icon="['fas', 'spinner']"
				></FontAwesomeIcon>
			</BCol>
		</BRow>
	</div>
</template>

<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import { BRow, BCol } from 'bootstrap-vue';
import IdMixin from '@/mixins/id';
import BaseButton from '@/components/common/base/BaseButton';
import BaseCard from '@/components/common/card/BaseCard';
import BaseModal from '@/components/common/base/BaseModal';
import loadingModal from '@/components/common/LoadingModal';
import RecentClaims from '@/models/RecentClaims';
import ClaimsTrackerTable from '@/components/claims-tracker/ClaimsTrackerTable';
import { CLAIM_HISTORY } from '@/constants/Routes.js';

@Component({
	name: 'DashboardClaimsTrackerTab',
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			required: true
		}
	},
	watch: {
		locale() {
			if (!this.isNoClaims) {
				this.loadRecentClaims(true);
			}
		}
	},
	components: {
		BRow,
		BCol,
		BaseCard,
		BaseButton,
		loadingModal,
		BaseModal,
		ClaimsTrackerTable
	}
})
export default class DashboardClaimsTrackerRow extends Vue {
	showLoading = false;
	hasLoaded = false;
	showModal = false;
	isEmptyResults = true;
	displayNoClaims = true;
	displayError = false;
	claims = [];
	totalRows = null;
	perPage = 10;
	showLoadMoreButton = false;
	userEmail = sessionStorage.getItem('email');
	storedNumberOfClaimsToShow = parseInt(sessionStorage.getItem('claimsPerPage'));

	mounted() {
		this.perPage = this.storedNumberOfClaimsToShow ? this.storedNumberOfClaimsToShow : 10;
		this.loadRecentClaims();
	}
	get locale() {
		return this.$store.state.i18n.locale;
	}
	get claimHistoryLink() {
		return CLAIM_HISTORY;
	}
	toFullClaimHistory() {
		this.$router.push({ name: CLAIM_HISTORY });
	}
	async loadRecentClaims(localeIsChanged) {
		this.showLoading = true;
		this.hasLoaded = false;
		this.displayError = false;
		this.displayNoClaims = false;
		if (!localeIsChanged) {
			this.claims = await RecentClaims.getSavedSearchResults(this.userEmail);
		}
		this.isEmptyResults = !this.claims?.length || false;
		if (this.isEmptyResults || localeIsChanged) {
			this.showLoading = true;
			let response = await RecentClaims.getRecentClaims(
				this.userEmail,
				this.$root.$i18n.locale,
				sessionStorage.getItem('apiToken')
			);
			// If status is 200 show results.
			// If 204 then display no claims
			// Else show error.
			if (response?.status === 200) {
				this.claims = response?.data;
				this.isEmptyResults = false;
			} else if (response?.status === 204) {
				this.displayNoClaims = true;
				this.isEmptyResults = true;
			} else {
				this.displayError = true;
				this.isEmptyResults = true;
			}
		}

		this.showLoading = false;
		this.hasLoaded = true;
		this.$emit('isEmptyClaims', this.isEmptyResults);
		this.totalRows = this.claims ? this.claims?.length : 0;
		this.showLoadMoreButton = this.perPage >= this.totalRows ? false : true;
	}
	showMore() {
		this.perPage += 10;
		this.showLoadMoreButton = this.perPage >= this.totalRows ? false : true;
		sessionStorage.setItem('claimsPerPage', this.perPage);
	}
}
</script>

<style lang="scss" scoped>
.claims-tracker-card {
	padding: 30px 15px;
}
.no-claims {
	font-weight: 400;
	font-size: 18px;
	font-family: 'Josefin Sans', sans-serif;
}
.claimHystoryLink {
	text-align: center;
}
</style>

<i18n>
{
	"en": {
		"title": "Recently submitted",
		"content": "Claims you have submitted in the last 30 days and their status. Newly submitted claims may take up to 12 hours to appear.",
		"noClaims": "We don't have any recently submitted claims on file.",
		"claimsError": "We're sorry, your recent claims are not available right now. Please try again later.",
		"claimHistoryButton": "View full claims history",
		"loadMore": "Load more",
		"drugClaims": "Looking for drug claims?",
		"modal": {
			"text": "Prescription drug claims filed directly through your pharmacy can be found on the {claimsHistory} screen.",
			"link": "Claims History"
			}
		},
	"fr": {
		"title": "Demandes de règlement soumises récemment",
		"content": "Demandes de règlement soumises dans les 30 derniers jours et leur état. Si vous venez tout juste de soumettre une demande de règlement, il peut s'écouler jusqu'à 12 heures avant qu'elle ne s'affiche sur la page ici.",
		"noClaims": "Nos dossiers ne contiennent aucune demande de règlement récente.",
		"claimsError": "Nous sommes désolés, vos récentes demandes de règlements ne sont pas accessibles pour le moment. Veuillez réessayer plus tard.",
		"claimHistoryButton": "Voir l’historique complet des demandes de règlement",
		"loadMore": "Afficher plus",
		"drugClaims": "Vous cherchez des demandes de règlement pour médicaments?",
		"modal": {
			"text": " Les demandes de règlement pour médicaments sur ordonnance soumises directement par votre pharmacie se trouvent à l’écran {claimsHistory} de règlement.",
			"link": "Historique des demandes"
			}
		}
}
</i18n>
