<template>
	<div>
		<ActionButtonCardContainer class="card-container">
			<BRow cols="2" cols-md="4">
				<ActionButtonCard
					v-if="securityPolicy && securityPolicy.submitClaim"
					:title="$t('card.submit')"
					:aria-label="$t('card.submit')"
					:icon="['fal', 'file-import']"
					:automation-id="getAutomationId('submit-claim')"
					@click="toSubmitClaim()"
				/>
				<ActionButtonCard
					:title="$t('card.member')"
					:aria-label="$t('card.member')"
					:icon="['fal', 'address-card']"
					:automation-id="getAutomationId('member-card')"
					@click="toMemberCard()"
				/>
				<ActionButtonCard
					v-if="bookletId"
					:title="$t('card.booklet')"
					:aria-label="$t('card.booklet')"
					:icon="['fal', 'file-pdf']"
					:automation-id="getAutomationId('booklet')"
					@click="getMemberBooklet()"
				/>
				<ActionButtonCard
					v-if="isLatestUpdateAvailable"
					:title="$t('card.updates')"
					:aria-label="$t('card.updates')"
					:icon="['fal', 'newspaper']"
					:automation-id="getAutomationId('update')"
					@click="toLatestUpdate()"
				/>
			</BRow>
		</ActionButtonCardContainer>
		<ErrorModal
			v-if="showBookletError"
			:error-title="$t('modal.title')"
			:error-message="$t('modal.message')"
			:has-close-button="false"
			:modal-show="showBookletError"
			:automation-id="getAutomationId('bookletErrorModal')"
			@change="showBookletError = false"
		>
			<BaseButton
				class="mt-2"
				:label="$t('button.ok')"
				:aria-label="$t('button.ok')"
				variant="primary"
				:automation-id="getAutomationId('bookletErrorCloseButton')"
				@click="showBookletError = false"
			/>
		</ErrorModal>
		<DirectDepositMissingModal
			:show-direct-deposit-modal="showDirectDepositModal"
			:update-banking-capable="updateBankingCapable"
			@closeModal="closeModal"
		/>
	</div>
</template>
<script>
import Vue from 'vue';
import Component from 'vue-class-component';
import IdMixin from '@/mixins/id';
import ActionButtonCardContainer from '@/components/common/card/ActionButtonCardContainer';
import ActionButtonCard from '@/components/common/card/ActionButtonCard';
import BaseCard from '@/components/common/card/BaseCard';
import CardIcon from '@/components/common/card/CardIcon';
import ErrorModal from '@/components/common/ErrorModal';
import BaseButton from '@/components/common/base/BaseButton';
import { BRow, BCol } from 'bootstrap-vue';
import { SUBMIT_CLAIM, MEMBER_CARD } from '@/constants/Routes';
import BenefitsBooklet from '@/models/coverage/BenefitsBooklet.js';
import DirectDepositInfoCall from '@/models/DirectDepositInfoCall.js';
import DirectDepositMissingModal from '@/components/common/DirectDepositMissingModal.vue';

@Component({
	name: 'DashboardActionRow',
	mixins: [IdMixin],
	props: {
		automationId: {
			type: String,
			required: true
		}
	},
	components: {
		BRow,
		BCol,
		ActionButtonCardContainer,
		ActionButtonCard,
		BaseCard,
		CardIcon,
		ErrorModal,
		BaseButton,
		DirectDepositMissingModal
	}
})
export default class DashboardActionRow extends Vue {
	showBookletError = false;
	bookletId = null;
	securityPolicy = false;
	showDirectDepositModal = false;
	updateBankingCapable = false;

	created() {
		this.securityPolicy = JSON.parse(sessionStorage.getItem('securityPolicy'));
		this.updateBankingCapable = this.securityPolicy?.updateBanking;
		this.getMemberBookletId();
	}

	get branding() {
		const { brand } = JSON.parse(sessionStorage.getItem('securityPolicy'));
		return brand;
	}

	get isLatestUpdateAvailable() {
		if (this.branding === 'OBC' || this.branding === 'QBC') {
			return false;
		}
		return true;
	}

	async toSubmitClaim() {
		this.$store.dispatch('updateLoading', true);
		const response = await DirectDepositInfoCall.getDirectDepositInfo(
			sessionStorage.getItem('email'),
			sessionStorage.getItem('apiToken'),
			this.$store.state.i18n.locale
		);
		if (response?.status === 204) {
			this.showDirectDepositModal = true;
		} else {
			this.$router.push({ name: SUBMIT_CLAIM.CLAIM_TYPE });
		}
		this.$store.dispatch('updateLoading', false);
	}

	toMemberCard() {
		this.$router.push({ name: MEMBER_CARD });
	}

	toLatestUpdate() {
		if (this.branding && this.branding === 'SBC') {
			window.open('https://www.sk.bluecross.ca/news/', '_blank');
		} else {
			if (this.$store.state.i18n.locale === 'en') {
				window.open('https://www.medaviebc.ca/member-services-updates', '_blank');
			} else {
				window.open('https://www.medaviebc.ca/fr/mises-a-jour-adherents', '_blank');
			}
		}
	}

	async getMemberBookletId() {
		try {
			const bId = await BenefitsBooklet.getBenefitsBookletId();
			if (bId !== null) {
				this.bookletId = bId;
			} else {
				this.bookletId = null;
			}
		} catch (e) {
			// this will show the booklet links but show error on fetching
			this.bookletId = null;
		}
	}

	async getMemberBooklet() {
		// if there was an error retreiving the booklet id, show the error modal
		if (!this.bookletId) {
			this.showBookletError = true;
		} else {
			// else go get the booklet with the id
			const success = await BenefitsBooklet.getBenefitsBooklet();
			if (!success) {
				this.showBookletError = true;
				this.bookletId = null;
			}
		}
	}

	/**
	 * Close the no direct depost modal.
	 */
	closeModal() {
		this.showDirectDepositModal = false;
	}
}
</script>

<style lang="scss" scoped>
.card-container {
	max-width: 750px;
}
</style>

<i18n>
{
	"en": {
		"card": {
			"submit": "Submit a Claim",
			"member": "Member Card",
			"booklet": "Benefits Booklet (PDF)",
			"updates": "Latest Updates"
		},
    "button": {
      "ok": "Ok"
    },
    "modal": {
      "title": "We aren't able to access your benefits booklet right now.",
      "message": "Give us a call and we'll get that figured out."
    }
	},
	"fr": {
		"card": {
			"submit": "Soumettre une demande de règlement",
			"member": "Carte d'identification ",
			"booklet": "Brochure de garanties (PDF)",
			"updates": "Nouvelles récentes"
		},
     "button": {
      "ok": "OK"
    },
    "modal": {
      "title": "Nous ne sommes pas en mesure d'accéder à votre brochure de garanties pour l'instant.",
      "message": "Appelez-nous et nous trouverons une solution au problème."
    }
	}
}
</i18n>
